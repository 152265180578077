<template>
  <div class="products">
    <Breadcrumb class="d-none d-sm-flex px-0" :items="breadcrumb" />

    <CategoryTitle :category="category" :count="count" />
    <v-container>
      <article-class-grid-list :articleClasses="articleClasses" />
      <article-accordion-list :articleList="articleList" :key="key" />
    </v-container>
  </div>
</template>
<script>
import Breadcrumb from "@/components/navigation/Breadcrumb.vue";
import ArticleClassGridList from "@/components/article/ArticleClassGridList.vue";
import ArticleAccordionList from "@/components/article/ArticleAccordionList.vue";

import CategoryTitle from "@/components/category/CategoryTitle.vue";

import cmService from "~/service/mksCmService";
import categoryMixins from "~/mixins/category";

export default {
  components: {
    Breadcrumb,
    ArticleAccordionList,
    ArticleClassGridList,
    CategoryTitle
  },
  name: "ArticleClassGrid",
  mixins: [categoryMixins],
  data() {
    return {
      articleClasses: [],
      selectedArticleClass: null,
      articleList: [],
      count: 0,
      key: 1
    };
  },
  methods: {
    async reload() {
      let parentArticleTypeId = this.$ebsn.meta(
        this.category,
        "template_model.ARTICLE_TYPE"
      );
      let getArticleContent = this.$ebsn.meta(
        this.category,
        "template_model.SHOW_CONTENT"
      );
      let sort = this.$ebsn.meta(this.category, "template_model.ARTICLE_SORT");
      let articleClassId = this.$route.query.articleClassId
        ? this.$route.query.articleClassId
        : null;
      let content = await cmService.searchArticle({
        parent_article_type_id: parentArticleTypeId,
        show_content: getArticleContent,
        article_class_id: articleClassId,
        sort: sort,
        page_size: 50
      });

      let articleClassName = this.$ebsn.meta(
        this.category,
        "template_model.ARTICLE_CLASS_GROUP"
      );
      this.articleClasses = content?.data?.facets?.find(
        item => item.name === articleClassName
      )?.values;
      this.articleList = content?.data?.articles;

      this.key++;
    }
  },
  created() {
    this.reload();
  },
  watch: {
    "$route.query.articleClassId": function() {
      this.reload();
    }
  }
};
</script>
